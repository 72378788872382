import React from "react";

const PageError = () => {
  return (
    <div className="error-page-container">
      <div className="h1 text-uppercase">Page unavailable</div>
      <div className="h6 mt-3">
        Your requested site is currently unavailable.
      </div>
      <div className="h6 mt-3">
        We’re not quite sure what went wrong. You can go back, or try looking on
        our <a href="mailto:clint@wealthx.au">Help Center</a> if you need a
        hand.
      </div>
    </div>
  );
};

export default PageError;
